import React from "react";
import { styled } from '@mui/system';
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

// Styled components
const BottomNavContainer = styled(BottomNavigation)({
  background: "black",
  height: "100px",
  overflow: "hidden",
});

const IconStyled = styled(BottomNavigationAction)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fill: "tan",
    "&:hover": {
      fill: "white",
      fontSize: "1.8rem",
    },
  },
}));

const Footer = () => {
  return (
    <BottomNavContainer>
      <a href="https://github.com/Izzienjeri" target="_blank" rel="noopener noreferrer">
        <IconStyled icon={<GitHubIcon />} />
      </a>
      <a href="https://www.linkedin.com/in/elizabeth-njeri-661912293" target="_blank" rel="noopener noreferrer">
        <IconStyled icon={<LinkedInIcon />} />
      </a>
      <a href="https://drive.google.com/file/d/1n18FSZqx4sRijBcXSEzElDF81C2gXUOU/view?usp=sharing" target="_blank" rel="noopener noreferrer">
        <IconStyled icon={<PictureAsPdfIcon />} />
      </a>
    </BottomNavContainer>
  );
};

export default Footer;
