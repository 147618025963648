import React, { useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import Home from "@mui/icons-material/Home";
import ContactMail from "@mui/icons-material/ContactMail";
import Person from "@mui/icons-material/Person";
import Menu from "@mui/icons-material/Menu";
import { styled } from '@mui/system';

import avatar from "../IzzieDevLogo.png";
import Footer from "../components/Footer";

// Styled components
const AppBarStyled = styled(AppBar)({
  background: "black",
  margin: 0,
});

const IconButtonStyled = styled(IconButton)({
  color: "tan",
});

const TypographyStyled = styled(Typography)({
  color: "white",
});

const MenuSliderContainer = styled(Box)({
  width: 250,
  background: "black",
  height: "100%",
});

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  display: "block",
  margin: "0.5rem auto",
  width: theme.spacing(15),
  height: theme.spacing(15),
}));

const ListItemStyled = styled(ListItem)({
  color: "tan",
});

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const menuItems = [
    { listIcon: <Home />, listText: "Home", listPath: "/" },
    { listIcon: <Person />, listText: "About Me", listPath: "/about" },
    { listIcon: <AssignmentInd />, listText: "Experience", listPath: "/resume" },
    // { listIcon: <Apps />, listText: "Portfolio", listPath: "/portfolio" },
    { listIcon: <ContactMail />, listText: "Contact", listPath: "/contact" },
  ];

  const sideList = () => (
    <MenuSliderContainer component="div">
      <AvatarStyled src={avatar} alt="izzie" />
      <Divider />
      <List>
        {menuItems.map((item, i) => (
          <ListItemStyled
            button
            key={i}
            onClick={() => setOpen(false)}
            component={Link}
            to={item.listPath}
          >
            <ListItemIcon>
              {item.listIcon}
            </ListItemIcon>
            <ListItemText primary={item.listText} />
          </ListItemStyled>
        ))}
      </List>
    </MenuSliderContainer>
  );

  return (
    <React.Fragment>
      <Box component="nav">
        <AppBarStyled position="static">
          <Toolbar>
            <IconButtonStyled onClick={() => setOpen(true)}>
              <Menu />
            </IconButtonStyled>
            <TypographyStyled variant="h5">
              Portfolio
            </TypographyStyled>
          </Toolbar>
        </AppBarStyled>
      </Box>
      <Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
        {sideList()}
        <Footer />
      </Drawer>
    </React.Fragment>
  );
};

export default Navbar;
