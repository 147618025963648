import React from "react";
import { styled } from '@mui/system';
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Send from "@mui/icons-material/Send";
import emailjs from "emailjs-com";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Styled components
const ContactContainer = styled(Box)({
  background: "black",
  height: "100vh",
});

const FormStyled = styled(Form)({
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  position: "absolute",
});

const Heading = styled(Typography)({
  color: "white",
  textAlign: "center",
  textTransform: "uppercase",
  marginBottom: "1rem",
});

const InputField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "white",
  },
  "& label": {
    color: "tan",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "tan",
    },
    "&:hover fieldset": {
      borderColor: "tan",
    },
    "&.Mui-focused fieldset": {
      color: "#fff",
      borderColor: "tan",
    },
  },
}));

const ButtonStyled = styled(Button)({
  marginTop: "1rem",
  color: "white",
  borderColor: "tan",
});

const FieldStyled = styled('div')({
  margin: "1rem 0rem",
});

const Contact = () => {
  const initialValues = {
    name: '',
    email: '',
    message: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    message: Yup.string().required('Message is required'),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await emailjs.send(
        "service_rb23ccx", // Your EmailJS Service ID
        "template_ybmf1qd", // Your EmailJS Template ID
        {
          name: values.name,
          email: values.email,
          message: values.message,
        },
        "FjnoGd26TgnnRvYtg" // Your EmailJS Public Key (User ID)
      );
      console.log("Email sent successfully!");
      resetForm();
      // Optionally, show a success message or redirect the user
    } catch (error) {
      console.error("Error sending email:", error);
      // Handle error (e.g., show error message)
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ContactContainer>
      <Grid container justifyContent="center">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <FormStyled>
              <Heading variant="h5">
                Contact me...
              </Heading>
              <FieldStyled>
                <Field
                  as={InputField}
                  fullWidth
                  label="Name"
                  variant="outlined"
                  name="name"
                />
                <ErrorMessage name="name" component="div" />
              </FieldStyled>
              <FieldStyled>
                <Field
                  as={InputField}
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                />
                <ErrorMessage name="email" component="div" />
              </FieldStyled>
              <FieldStyled>
                <Field
                  as={InputField}
                  fullWidth
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  name="message"
                />
                <ErrorMessage name="message" component="div" />
              </FieldStyled>
              <ButtonStyled
                type="submit"
                variant="outlined"
                fullWidth
                endIcon={<Send />}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Contact Me'}
              </ButtonStyled>
            </FormStyled>
          )}
        </Formik>
      </Grid>
    </ContactContainer>
  );
};

export default Contact;
