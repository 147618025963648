import React from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from '@mui/system';
import avatar from "../IzzieDevLogo.png";
import Particles from "./Particles";

// Styled components
const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(30),
  height: theme.spacing(30),
  margin: theme.spacing(1),
}));

const AboutContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
  padding: theme.spacing(4),
  maxWidth: "600px",
  margin: "0 auto",
}));

const AboutText = styled(Typography)(({ theme }) => ({
  color: "tan",
  marginTop: theme.spacing(2),
  fontSize: "1rem",
}));

const About = () => {
  return (
    <>
      <Particles />
      <AboutContainer>
        <Grid container justifyContent="center" alignItems="center">
          <AvatarStyled src={avatar} alt="izzie" />
        </Grid>
        <Typography variant="h4" component="h1" sx={{ color: 'tan' }}>
          Hello, I'm Elizabeth Njeri
        </Typography>
        <AboutText variant="body1">
          A Software Engineer passionate about transforming ideas into digital realities. With a Bachelor's in Mathematics and Computer Science from Multimedia University of Kenya and training from Moringa School in Software Engineering, I've honed my skills across various frameworks and languages.
        </AboutText>
        <AboutText variant="body2">
          In web development, I excel in Flask, Django, Next.js, React, TypeScript, JavaScript, Python, Tailwind CSS, and Admin LTE, crafting captivating frontend experiences and robust backend systems with seamless REST API integrations.
        </AboutText>
        <AboutText variant="body2">
          My expertise extends to AI optimization, where I optimize performance by delving into intricate backend codes, analyze diverse subject matter for AI interactions, and ensure accuracy and safety in critical applications. Beyond coding, I'm a natural leader, having supervised teams to improved efficiency by 20%. I foster a culture of continuous learning and enjoy reading to stay ahead in this ever-evolving tech landscape. Let's collaborate and bring your digital dreams to life!
        </AboutText>
      </AboutContainer>
    </>
  );
};

export default About;
