import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typed from 'typed.js';
import avatar from "../IzzieDevLogo.png";

const AvatarStyled = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  margin: theme.spacing(1),
}));

const TitleStyled = styled(Typography)(({ theme }) => ({
  color: 'white',
}));

const SubtitleStyled = styled(Typography)(({ theme }) => ({
  color: 'tan',
  textTransform: 'uppercase',
}));

const TypedContainerStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '100vw',
  textAlign: 'center',
  zIndex: 1,
}));

const Header = () => {
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      new Typed(titleRef.current, {
        strings: ["Elizabeth Njeri"],
        typeSpeed: 40,
        backSpeed: 0,
        backDelay: 0,
        startDelay: 0,
      });
    }
    if (subtitleRef.current) {
      new Typed(subtitleRef.current, {
        strings: ["Frontend Engineer", "Backend Engineer", "Project Manager"],
        typeSpeed: 40,
        backSpeed: 50,
        backDelay: 1000,
        startDelay: 0,
        loop: true,
      });
    }
  }, []);

  return (
    <TypedContainerStyled>
      <Grid container justifyContent="center">
        <AvatarStyled src={avatar} alt="Izzie" />
      </Grid>
      <TitleStyled variant="h4">
        <span ref={titleRef}></span>
      </TitleStyled>
      <SubtitleStyled variant="h5">
        <span ref={subtitleRef}></span>
      </SubtitleStyled>
    </TypedContainerStyled>
  );
};

export default Header;
